<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-text title="Title|Titre" :value="block.title" v-bind="componentProps"/>
        <properties-component-text title="Subtitle|Sous-titre" :value="block.subtitle" v-bind="componentProps"/>
        <properties-component-button title="Button" :value="block.button" v-bind="componentProps"/>
        <properties-component-text title="Description" :value="block.description" v-bind="componentProps"/>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-button #hot-reload-debug
export default {
    name: `properties-block-button`,
    components: {PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
